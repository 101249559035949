import { withFormik } from 'formik'

import type { FTCustomerAccessType } from './UserForm'
import UserForm from './UserForm'
import validationSchema from './validationSchema'
import {
  denaliUserGroupsApiNames,
  maUserRolesApiNames,
  userGroupsMap,
} from '../../ducks/users'
import type { FTUser } from '../../ducks/users'

type FTProps = {
  isAdmin: boolean
  submitAction: (...args: Array<any>) => any
  user: FTUser | null | undefined
}
export type FTUserFormValues = {
  customers: Record<string, FTCustomerAccessType>
  email: string
  firstName?: string
  lastName?: string
  source: string
  phone?: string
  company?: string
  groups: {
    accountManagers: boolean
    admin: boolean
    billing: boolean
    billingAdmin: boolean
    billingIssueInvestigator: boolean
    billingInvoiceApprover: boolean
    billingPerformanceReviewer: boolean
    billingReadOnly: boolean
    denaliAdmin: boolean
    hvacAssetsUploader: boolean
    ltgAsBuiltUploader: boolean
    manufacturing: boolean
    operationsSupport: boolean
    proposalOperations: boolean
    proposalOperationsAdmin: boolean
    proposalOperationsReadonly: boolean
    mobileAppMeterInstaller: boolean
    mobileAppAdministrator: boolean
    isrProcessorTool: boolean
    energyStarCoordinator: boolean
    meterInstallTrackerAccess: boolean
  }
  sites: Array<string>
  systemAccessDashboardUser: boolean
  systemAccessDenaliUser: boolean
  systemAccessGcUser: boolean
  systemAccessMaUser: boolean
  sendWelcomeEmail: boolean
  sendWelcomeEmailR1: boolean
  userId: string
}

const getDashboardUserDefaultValue = ({
  isAdmin,
  userId,
  groups,
}: {
  isAdmin: boolean
  userId?: string
  groups?: Record<string, boolean>
}): boolean => {
  // If the logged in user is an admin or this is the edit form...
  if (isAdmin || userId) {
    return !!(groups && groups.includes('user'))
  }

  // The user is not an admin and this is the create form.
  return true
}

const getMaUserDefaultValue = ({
  isAdmin,
  userId,
  groups,
}: {
  isAdmin: boolean
  userId?: string
  groups?: Record<string, boolean>
}): boolean => {
  // If the logged in user is an admin or this is the edit form...
  if (isAdmin || userId) {
    return !!(
      groups && maUserRolesApiNames.some((group) => groups.includes(group))
    )
  }

  // The user is not an admin and this is the create form.
  return true
}

const getDenaliUserDefaultValue = ({
  isAdmin,
  userId,
  groups,
}: {
  isAdmin: boolean
  userId?: string
  groups?: Record<string, boolean>
}): boolean => {
  // If the logged in user is an admin or this is the edit form...
  if (isAdmin || userId) {
    return (
      !!(groups && groups.includes('user')) ||
      !!(groups && groups.includes('denali-admin'))
    )
  }

  // The user is not an admin and this is the create form.
  return true
}
const getDenaliPerformanceAndInvoiceUserDefaultValue = ({
  isAdmin,
  userId,
  groups,
}: {
  isAdmin: boolean
  userId?: string
  groups?: Record<string, boolean>
}): boolean => {
  // If the logged in user is an admin or this is the edit form...
  if (isAdmin || userId) {
    return !!(groups && groups.includes('r1-performance-invoice-views'))
  }

  // The user is not an admin and this is the create form.
  return true
}

const getGcUserDefaultValue = (groups?: Record<string, boolean>): boolean =>
  groups &&
  groups
    .filter((group) => !['user'].includes(group))
    .filter((group) => !denaliUserGroupsApiNames.includes(group))
    .filter((group) => !maUserRolesApiNames.includes(group)).length

export const getDefaultValues = ({
  isAdmin,
}: {
  isAdmin: boolean
}): FTUserFormValues => ({
  customers: {},
  email: '',
  firstName: '',
  lastName: '',
  source: 'Grand Central',
  phone: '',
  company: '',
  groups: {
    accountManagers: false,
    admin: false,
    billing: false,
    billingAdmin: false,
    denaliAdmin: false,
    hvacAssetsUploader: false,
    energyStarAdmin: false,
    ltgAsBuiltUploader: false,
    manufacturing: false,
    operationsSupport: false,
    proposalOperations: false,
    proposalOperationsAdmin: false,
    proposalOperationsReadonly: false,
    mobileAppMeterInstaller: false,
    mobileAppAdministrator: false,
    energyStarCoordinator: false,
    meterInstallTrackerAccess: false,
    isrProcessorTool: false,
  },
  sites: [],
  systemAccessDashboardUser: getDashboardUserDefaultValue({
    isAdmin,
  }),
  systemAccessDenaliUser: getDenaliUserDefaultValue({
    isAdmin,
  }),
  systemAccessGcUser: getGcUserDefaultValue(),
  systemAccessMaUser: getMaUserDefaultValue({
    isAdmin,
  }),
  sendWelcomeEmail: true,
  sendWelcomeEmailR1: true,
  userId: '',
})
const FormikUserForm = withFormik({
  validationSchema,
  validateOnBlur: false,
  validateOnChange: false,

  handleSubmit(
    values,
    {
      props,
    }: {
      props: FTProps
    },
  ) {
    const { submitAction } = props
    submitAction(values)
  },

  mapPropsToValues(props: FTProps): FTUserFormValues {
    const { isAdmin, user } = props
    const {
      associatedCustomers = [],
      associatedSites = [],
      email = '',
      firstName = '',
      lastName = '',
      phone = '',
      company = '',
      source = 'Grand Central',
      groups,
      sendWelcomeEmail = false,
      sendWelcomeEmailR1 = false,
      id: userId = '',
    } = user || {}
    const customersValues = associatedCustomers.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: 'allSites' }),
      {},
    )
    associatedSites.forEach((site) => {
      customersValues[site.customerId] = 'selectSites'
    })
    const sitesValues = associatedSites.map(({ id }) => id)

    const getGroups = (): Record<string, boolean> =>
      Object.keys(userGroupsMap).reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: !!(groups && groups.includes(userGroupsMap[cur].apiName)),
        }),
        {},
      )

    return {
      customers: customersValues,
      email,
      firstName,
      lastName,
      phone,
      company,
      source,
      groups: getGroups(),
      sites: sitesValues,
      systemAccessDashboardUser: getDashboardUserDefaultValue({
        isAdmin,
        userId,
        groups,
      }),
      systemAccessDenaliUser: getDenaliUserDefaultValue({
        isAdmin,
        userId,
        groups,
      }),
      systemAccessGcUser: getGcUserDefaultValue(groups),
      systemAccessMaUser: getMaUserDefaultValue({
        isAdmin,
        userId,
        groups,
      }),
      sendWelcomeEmail,
      sendWelcomeEmailR1,
      userId,
    }
  },
})(UserForm)
export default FormikUserForm
