import styled from 'styled-components'

import { SearchBarStyled } from '../../components/EntityList/SearchBar'
import { SelectStyles } from '../../components/ListSelector'
import {
  ReactTableStyled,
  ReactTableWrapperStyles,
  TdInnerStyled,
} from '../../components/RedaptiveReactTable7'
import { colors } from '../../utils/themes'

export const Cell = styled.span<{ maxWidth: number }>`
  display: inline-block;
  max-width: ${(props) => props.maxWidth}px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TableStyles = styled.div`
  table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 8px;
  }
  thead th {
    background: none;
  }
  th {
    &:nth-child(1) {
        width: 165px;
    }
    &:nth-child(3) {
        width: 250px;
    }
    &:nth-child(4) {
        width: 200px;
    }
    &:nth-child(5) {
        width: 300px;
    }
    &:nth-child(6) {
        width: 300px;
    }
    padding-left: 10px;
  }
  td {
    border: 0.5px solid #dadce0;
    padding-left: 10px;
    &:first-child {
      > ${TdInnerStyled} {
        margin-left: 4px;
        padding-left: 0;
      }
    }
  }
  ${ReactTableWrapperStyles} {
    border-top: none;
  }
  ${ReactTableStyled} {
    &:last-child {
      ${SearchBarStyled} {
        width: 80px;
      }
    }
  }
  tr {
    border: none
  }
  td {
    background: ${colors.gray9};    
  }
  }
`

export const BreadcrumbsStyles = styled.div`
  margin-bottom: 24px;
`

export const TitleStyles = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
`

export const MainStyles = styled.div`
  padding-bottom: 80px;
  margin-top: 36px;

  * {
    box-sizing: border-box;
  }

  a {
    color: ${colors.blue2};
  }

  ${SearchBarStyled} {
    margin-bottom: 15px;
  }
`

export const JobStatusStyles = styled.span<{ inProgress: boolean }>`
  color: ${(props) => (props.inProgress ? colors.orange : colors.green)};
  font-weight: 500;
`

export const OpportunityStyles = styled.span`
  color: ${colors.blue15};
  font-weight: 600;
  min-width: 140px;
  display: block;
  display: flex;
`

export const ArrowUpStyles = styled.span`
  margin-left: 35px;
  position: relative;
  top: 7px;
`
export const PaginatorStyles = styled.div`
  padding-top: 31px;
  padding-bottom: 40px;

  .pager {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .current {
    background-color: ${colors.orange4} !important;
  }
`
export const PageSizeSelectorWrapperStyled = styled.div`
  float: right;
  position: relative;
  ${SelectStyles} {
    margin-left: 8px;
    width: 120px;
  }
`
export const PageSizeSelectorInnerStyled = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0px;
`

export const FilterContainerStyled = styled.div<{ width: number }>`
  input {
    width: ${({ width }) => `${width}px`};
  }
  font-weight: 400;
  width: ${({ width }) => `${width}px`};
}`

export const NoDataWrapper = styled.div`
  display: grid;
  place-items: center;
  font-size: 16px;
  background: ${colors.gray9};
  color: ${colors.gray2};
  height: 235px;
`
